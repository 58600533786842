import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import MetaTags from './util/meta-tags';

const BuilderPageMetadata = ({
  title,
  shareTitle,
  description,
  image,
  twitterCard,
  twitterSite,
  additionalMetadata,
  jsonLd,
}) => {

  const meta = new MetaTags()
    .title(shareTitle || title)
    .description(description)
    .image(image && image.imageFile)
    .openGraph('image:width', image && image.width)
    .openGraph('image:height', image && image.height)
    .twitter('card', twitterCard)
    .twitter('site', twitterSite)
    .pushAll(additionalMetadata);

  const jsonLdScript = 
    jsonLd ? 
    <script 
      type='application/ld+json' 
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    /> 
    : null;

  return (
    <Head>
      <title key='title'>{shareTitle || title}</title>
        {meta.tags.map((m) => {
          const key = m?.name || m?.property || m?.content;
          return (
              <meta key={key} {...m} />
          )
        })}
      {jsonLdScript}
    </Head>
  )
};

BuilderPageMetadata.propTypes = {
  title: PropTypes.string,
  shareTitle: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape({
    imageFile: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  twitterCard: PropTypes.string,
  twitterSite: PropTypes.string,
  additionalMetadata: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    property: PropTypes.string,
    content: PropTypes.string,
    httpEquiv: PropTypes.string,
  })),
  jsonLd: PropTypes.shape({}),
};

BuilderPageMetadata.defaultProps = {
  title: null,
  shareTitle: null,
  description: null,
  image: {
    imageFile: null,
    width: null,
    height: null
  },
  twitterCard: null,
  twitterSite: null,
  additionalMetadata: [],
  jsonLd: null,
};

export default BuilderPageMetadata;
