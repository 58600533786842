import { createContext } from "react";

export const MagicSvgContext = createContext({
  userToken: {},
  image: {},
  recentImages: [],
  generationStatus: '',
  showBanner: false,
  userAction: '',
});

export const MagicSvgDispatchContext = createContext(null);
