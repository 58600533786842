const optimizelyTrackExperience = () => {
    if (!window.optimizely || !window.analytics) return;

    const state = window.optimizely?.get?.("state");
    const experimentIds = state?.getActiveExperimentIds?.();
    const experimentStates = state?.getExperimentStates?.({
        "isActive": true
    });

    if (experimentIds?.length === 0 || !experimentStates) return;

    experimentIds.forEach(experimentId => {
        const experiment = experimentStates[experimentId]
        if (experiment) {
            window.analytics.track("Experiment Viewed", {
                experiment_id: experiment.id,
                experiment_name: experiment.experimentName,
                variation_id: experiment.variation.id,
                variation_name: experiment.variation.name,
                testing_service: 'optimizely.com',
            });
        }
    })
}

const builderTrackExperience = (content) => {
    if (!content?.testVariationId || !content?.testVariationName) return;

    const { id, name, testVariationId, testVariationName } = content;

    if (window.analytics) {
        window.analytics.track('Experiment Viewed', {
            experiment_id: id,
            experiment_name: name,
            variation_id: testVariationId,
            variation_name: testVariationName,
            testing_service: 'builder.io',
        });
    }
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'experiment_viewed',
            experiment_id: id,
            experiment_name: name,
            variation_id: testVariationId,
            variation_name: testVariationName,
            testing_service: 'builder.io',
        });
    }
}

export {
    optimizelyTrackExperience,
    builderTrackExperience
}