import React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'

// This exists because the helmet implementation allowed for titleTemplate prop.
const HeadTitle = ({title}) => (
    <Head>
      <title>{`${title} | Glowforge`}</title>
    </Head>
)

HeadTitle.propTypes = {
  title: PropTypes.string,
}

HeadTitle.defaultProps = {
  title: '',
}

export default HeadTitle