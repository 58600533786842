import * as React from 'react';
import ShareProgramContext from './ShareProgramContext';

export default function withShareProgram(Component) {
  return function ShareProgramComponent(props) {
    return (
      <ShareProgramContext.Consumer>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {(contexts) => <Component {...props} {...contexts} />}
      </ShareProgramContext.Consumer>
    );
  };
}
