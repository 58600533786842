/* eslint-disable import/prefer-default-export */
import { fetchApi } from "../util/fetch-api";

/**
/**
 * Attempts to fetch the current user subscription from ecom
 * Sends a request to the '/ecom/api/v1/subscriptions/current' endpoint.
 * @returns {Promise<Object>} The user data or null if not authenticated.
 */
export async function ensureSubscriptionLoaded() {
  try {
    await fetchApi('/ecom/api/v1/subscriptions/current');
  } catch (error) {
    console.error('Error fetching current user subscription:', error);
  }
}
