const parseReferralDiscount = (products) => {
  const {
    pro: { referral: { discount: proString = '0' } = {} } = {},
    plus: { referral: { discount: plusString = '0' } = {} } = {},
    basic: { referral: { discount: basicString = '0' } = {} } = {},
  } = products || {};

  const proDiscount = Math.abs(parseInt(proString, 10));
  const plusDiscount = Math.abs(parseInt(plusString, 10));
  const basicDiscount = Math.abs(parseInt(basicString, 10));

  return {
    proDiscount,
    plusDiscount,
    basicDiscount,
  };
}

export default parseReferralDiscount;
