import React from 'react';
import PropTypes from 'prop-types';

import getConfig from 'next/config';
import Head from 'next/head';

const config = getConfig();
const { GF_DOTCOM_ENDPOINT } = config.publicRuntimeConfig;

const Canonical = ({path}) => {
  let sanitizedpath = path;
  
  // Check to ensure if first char is '/' to remove it. 
  if(path.charAt(0) === '/') {
    sanitizedpath = path.substring(1);
  }
  return (
    <Head>
      <link rel='canonical' href={`${GF_DOTCOM_ENDPOINT}/${sanitizedpath}`} />
    </Head>
  )
}

Canonical.propTypes = {
  path: PropTypes.string.isRequired
}

export default Canonical