// Disabling the restricted default import because
// it's needed for how context are expected to work
/* eslint-disable no-restricted-exports */
export { default } from './ShareProgramContext';

export {
  shareProgramContextDefaultProp,
  shareProgramContextPropType,
} from './shareProgramContextPropType';
export { default as ShareProgramProvider } from './ShareProgramProvider';
export { default as withShareProgram } from './withShareProgram';
export { default as parseReferralDiscount } from './parseReferralDiscount';
export {
  parseAffiliateURL,
  setShareProgramCookie,
} from './shareProgramUtils';
