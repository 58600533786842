/**
 * Based on the one found in gf-styled
 * MetaTag Helper class.
 * inject in to the page.
 * react-isomorphic-render injects meta, link, and title tags in to the head on 
 * the server side.
 * It does not inject script tags. See:
 * https://github.com/catamphetamine/react-website/blob/11/source/page-server/html.js#L57
 */

export default class MetaTags {
  constructor() {
    this.tags = [];
  }

  push(meta) {
    if (meta.content) {
      this.tags.push(meta);
    }
    return this;
  }

  pushAll(metaArray) {
    metaArray.forEach(entry => {
      this.push.bind(entry);
    });
    return this;
  }

  name(name, content) {
    return this.push({ name, content });
  }

  property(property, content) {
    return this.push({ property, content });
  }

  twitter(name, content) {
    return this.name(`twitter:${name}`, content);
  }

  openGraph(name, content) {
    return this.property(`og:${name}`, content);
  }

  // The following helpers are designed to support the Page Metadata
  // coming from Builder
  title(value) {
    return this
      .twitter('title', value)
      .openGraph('title', value);
  }

  description(value) {
    return this
      .name('description', value)
      .twitter('description', value)
      .openGraph('description', value);
  }

  image(value) {
    return this
      .twitter('image', value)
      .openGraph('image', value);
  }
}
