import PropTypes from 'prop-types';

export const referralInfoPropType = PropTypes.shape({
  name: PropTypes.string,
  code: PropTypes.string,
});

export const affiliateInfoPropType = PropTypes.shape({
  name: PropTypes.string,
  code: PropTypes.string,
});

export const shareProgramContextPropType = {
  shareProgramContext: PropTypes.shape({
    referralInfo: referralInfoPropType,
    affiliateInfo: affiliateInfoPropType,
  }),
};

export const shareProgramContextDefaultProp = {
  shareProgramContext: {
    referralInfo: undefined,
    affiliateInfo: undefined,
  },
};
