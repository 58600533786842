import { builder } from '@builder.io/react/lite';
import { buildFetchContext, fetchWithoutToken } from '../util/fetch-api';
import { fetchUser } from './storefront';
import { ensureSubscriptionLoaded } from './subscription';

export async function generateImage(data, options = {}) {
  const { fetchWithToken } = buildFetchContext(options.context);

  try {
    const response = await fetchWithToken('/functions/generate-image-2', {
      body: JSON.stringify({
        ...data,
        useToken: true,
        save: true,
        trace: true,
      }),
    });

    return response;
  } catch (e) {
    throw new Error(e);
  }
}

export async function fetchUserToken(options = {}) {
  const { fetchWithToken } = buildFetchContext(options.context);
  const user = await fetchUser();
  await ensureSubscriptionLoaded();

  try {
    const response = await fetchWithToken(
      `/functions/user/tokenomics-status?email=${encodeURIComponent(user.email)}`,
    );
    return response;
  } catch (e) {
    throw new Error(e);
  }
}

export async function fetchRecentImages(options = {}) {
  const { fetchWithToken } = buildFetchContext(options.context);

  try {
    const response = await fetchWithToken('/functions/generate-image-2/images?order=desc');
    return response;
  } catch (e) {
    throw new Error(e);
  }
}

export async function fetchArtStyles(options = {}) {
  const artStyles = await builder.getAll('magic-svg-art-styles', options);
  if (!artStyles) return null;

  return artStyles.map(({ data }) => data);
}

export async function fetchSpecificImage(id) {
  try {
    const response = await fetchWithoutToken(`/functions/generate-image-2/images/${id}`);
    return response.json();
  } catch (e) {
    throw new Error(e);
  }
}

export async function postDelivery(id, options = {}) {
  const { fetchWithToken } = buildFetchContext(options.context);

  try {
    const response = await fetchWithToken('/functions/generate-image-2/images/deliver', {
      method: 'POST',
      body: JSON.stringify({ imageId: id }),
    });
    return response;
  } catch (e) {
    throw new Error(e);
  }
}

export async function deleteImage(id, options = {}) {
  const { fetchWithToken } = buildFetchContext(options.context);

  try {
    const response = await fetchWithToken(`/functions/generate-image-2/images/${id}`, {
      method: 'DELETE',
    });
    return response;
  } catch (e) {
    throw new Error(e);
  }
}
